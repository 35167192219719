import { AdsTrafficParams, AffiliateTrafficParams, UtmTrafficParams } from '../types/tracker';
import { useAirpazCookie, useSessionStorage, safelyParseJSON, computed, safeAtob, safeBtoa } from '#imports';

const trafficSourceReferrerMap: { [key: string]: string } = {
  'l.facebook.com': 'FB',
  google: 'GOOG',
  'goo.gl': 'GURL',
  bing: 'BING',
  naver: 'NAVER',
  'yahoo.co.jp': 'YHOOJP',
  yahoo: 'YHOO',
  baidu: 'BAIDU',
  'ow.ly': 'HOOT',
  'connect.airpaz': 'MAIL',
  'youtube.com': 'YOUTUBE',
  'blog.airpaz': 'BLOG',
  '/widget/search': 'WIDGET',
  'msn.com': 'MSN',
  'coccoc.com': 'COCCOC',
  'ecosia.org': 'ECOSIA',
  'hao123.com': 'HAO123',
  'daum.net': 'DAUM',
  'sogou.com': 'SOGOU',
  'so.com': 'SO',
  'duckduckgo.com': 'DUCKDUCKGO',
  'yandex.com': 'YANDEX',
};

const TRAFFIC_MAX_AGE = 24 * 60 * 60; // 1 day

export const getTrafficSourceByReferrer = (sourceReferrer: string) => {
  let trafficSource = null;

  for (const referrer in trafficSourceReferrerMap) {
    if (sourceReferrer.includes(referrer)) {
      trafficSource = trafficSourceReferrerMap[referrer];
      break;
    }
  }

  return trafficSource ?? null;
};

export const useTrafficCookie = () => {
  const ads = useAirpazCookie('ads_traffic', { maxAge: TRAFFIC_MAX_AGE });
  const affiliate = useAirpazCookie('affiliate_params', { maxAge: TRAFFIC_MAX_AGE });
  const utm = useAirpazCookie('utm_traffic', { maxAge: TRAFFIC_MAX_AGE });

  const adsModel = computed({
    get: () => safelyParseJSON<AdsTrafficParams>(safeAtob(ads.value ?? '')),
    set: (value) => {
      ads.value = safeBtoa(JSON.stringify(value));
    },
  });
  const affiliateModel = computed({
    get: () => safelyParseJSON<AffiliateTrafficParams>(safeAtob(affiliate.value ?? '')),
    set: (value) => {
      affiliate.value = safeBtoa(JSON.stringify(value));
    },
  });
  const utmModel = computed({
    get: () => safelyParseJSON<UtmTrafficParams>(safeAtob(utm.value ?? '')),
    set: (value) => {
      utm.value = safeBtoa(JSON.stringify(value));
    },
  });

  return {
    ads: adsModel,
    affiliate: affiliateModel,
    utm: utmModel,
  };
};

export const useTrafficSession = () => {
  const ads = useSessionStorage('referral', '', { writeDefaults: false });
  const affiliate = useSessionStorage('affiliate_params', '', { writeDefaults: false });
  const utm = useSessionStorage('utm_traffic', '', { writeDefaults: false });

  const adsModel = computed({
    get: () => safelyParseJSON<AdsTrafficParams>(safeAtob(ads.value)),
    set: (value) => {
      ads.value = safeBtoa(JSON.stringify(value));
    },
  });
  const affiliateModel = computed({
    get: () => safelyParseJSON<AffiliateTrafficParams>(safeAtob(affiliate.value)),
    set: (value) => {
      affiliate.value = safeBtoa(JSON.stringify(value));
    },
  });
  const utmModel = computed({
    get: () => safelyParseJSON<UtmTrafficParams>(safeAtob(utm.value ?? '')),
    set: (value) => {
      utm.value = safeBtoa(JSON.stringify(value));
    },
  });

  return {
    ads: adsModel,
    affiliate: affiliateModel,
    utm: utmModel,
  };
};

export const defineTrafficSource = (
  adsTraffic: AdsTrafficParams | null,
  affiliateTraffic: AffiliateTrafficParams | null,
) => {
  const traffic = {
    source: null as string | null,
    sourcePar: null as AdsTrafficParams | AffiliateTrafficParams | null,
  };

  if (adsTraffic?.s || adsTraffic?.a) {
    traffic.source = adsTraffic.s || adsTraffic.a || null;
    traffic.sourcePar = adsTraffic;
  }

  if (
    affiliateTraffic?.id &&
    (!traffic.source || (traffic.source && Object.values(trafficSourceReferrerMap).includes(traffic.source)))
  ) {
    traffic.source = affiliateTraffic.id;
    traffic.sourcePar = affiliateTraffic;
  }

  return traffic;
};
