import {
  CLSMetricWithAttribution,
  INPMetricWithAttribution,
  LCPMetricWithAttribution,
  onINP,
  onLCP,
  onCLS,
} from 'web-vitals/attribution';
import { useRuntimeConfig, defineNuxtPlugin, useAirpazCookie } from '#imports';

export default defineNuxtPlugin(() => {
  const appConfig = useAppConfig();
  const runtimeConfig = useRuntimeConfig();
  const debug = useAirpazCookie('debug_gtag');
  const { dataLayer } = useScriptGoogleTagManager({ id: runtimeConfig.public.gtmId });

  function gtag() {
    if (debug.value) {
      // eslint-disable-next-line prefer-rest-params
      console.warn('[gtag]', arguments);
    }

    // eslint-disable-next-line prefer-rest-params
    dataLayer?.push(arguments);
  }

  // @ts-expect-error
  gtag('js', new Date());
  // @ts-expect-error
  gtag('config', runtimeConfig.public.gaId, {
    anonymize_ip: false, // anonymize IP
    send_page_view: false, // might be necessary to avoid duplicated page track on page reload
    linker: {
      domains: ['airpaz.com'],
    },
    allow_enhanced_conversions: true,
  });

  function injectedGtag(type: string, eventName: string, params: any) {
    if (process.dev) {
      console.warn('[gtag]', { type, eventName, params });

      return;
    }

    try {
      // @ts-expect-error
      gtag(type, eventName, params);
    } catch {}
  }

  useScriptEventPage((page) => {
    injectedGtag('event', 'page_view', {
      page_title: page.title,
      page_path: page.path,
      page_location: window.location.href,
      send_to: runtimeConfig.public.gaId,
    });
  });

  if (
    appConfig.projectName === 'blaze' ||
    (appConfig.projectName === 'forerunner' && useRouteBaseName()(useRoute()) === 'index')
  ) {
    try {
      function sendWebVitalsToGoogleAnalytics({
        name,
        delta,
        value,
        id,
        rating,
        attribution,
      }: INPMetricWithAttribution | LCPMetricWithAttribution | CLSMetricWithAttribution) {
        try {
          const eventParams = {
            send_to: runtimeConfig.public.gaId,
            value: delta,
            metric_id: id,
            metric_value: value,
            metric_delta: delta,
            metric_rating: rating,
          } as { [key: string]: any };

          switch (name) {
            case 'LCP':
              eventParams.debug_target = attribution.element;
              eventParams.debug_src = attribution.url;
              eventParams.debug_ttfb = attribution.timeToFirstByte;
              eventParams.debug_src_load_delay = attribution.resourceLoadDelay;
              eventParams.debug_src_load_time = attribution.resourceLoadTime;
              break;
            case 'INP':
              eventParams.debug_target = attribution.eventTarget;
              eventParams.debug_type = attribution.eventType;
              eventParams.debug_load_state = attribution.loadState;
              eventParams.debug_duration = attribution.eventEntry?.duration;
              eventParams.debug_start_time = attribution.eventEntry?.startTime;
              eventParams.debug_processing_start = attribution.eventEntry?.processingStart;
              eventParams.debug_processing_end = attribution.eventEntry?.processingEnd;
              break;
            case 'CLS':
              eventParams.debug_target = attribution.largestShiftTarget;
              eventParams.debug_load_state = attribution.loadState;
              break;
          }

          injectedGtag('event', name, eventParams);
        } catch {}
      }

      onLCP(sendWebVitalsToGoogleAnalytics);
      onINP(sendWebVitalsToGoogleAnalytics, { reportAllChanges: true });
      onCLS(sendWebVitalsToGoogleAnalytics);
    } catch {}
  }

  return {
    provide: {
      gtag: injectedGtag,
    },
  };
});
