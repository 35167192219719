import { defineNuxtPlugin } from '#app';
import { Currency } from '@/types/master';

import { useConfigStore } from '#imports';

type CurrencyFormatterOptions = {
  hideSymbol: boolean;
  forceDecimal: boolean;
  negativePrefix: string;
};

const DEFAULT_OPTIONS = {
  hideSymbol: false,
  forceDecimal: false,
  negativePrefix: '- ',
};
const isNegative = (value: string | number, negativePrefix = DEFAULT_OPTIONS.negativePrefix) =>
  typeof value === 'string' ? value.startsWith(negativePrefix) : typeof value === 'number' ? value < 0 : false;

export default defineNuxtPlugin(() => {
  const config = useConfigStore();

  return {
    provide: {
      c(
        value: number,
        currency: string | Currency = config.currencyConfig!,
        options?: Partial<CurrencyFormatterOptions>,
      ) {
        if (!config.currencyConfig || typeof value !== 'number') {
          return String(value);
        }

        const currencyConfig = typeof currency === 'string' ? config.currencyConfig : currency;

        let formattedValue: any = Math.abs(value);
        const { hideSymbol, negativePrefix, forceDecimal } = { ...DEFAULT_OPTIONS, ...options };
        const { symbol, thousand, precision = 0 } = currencyConfig;
        const exemptPrecisionCurrencies = ['BND', 'MYR'];

        formattedValue =
          forceDecimal && precision > 0
            ? Number(formattedValue).toFixed(precision)
            : Number(Number(formattedValue).toFixed(precision));

        if (precision > 0 && !exemptPrecisionCurrencies.includes(currencyConfig.currency)) {
          let currency = currencyConfig.currency.substr(0, 2);
          if (currency === 'SA') {
            currency = 'en-SA';
          }
          formattedValue = formattedValue.toLocaleString(currency).replace(/\B(?=(\d{3})+(?!\d))/g, thousand);
        } else {
          formattedValue = formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousand);
        }

        if (!hideSymbol) {
          formattedValue = symbol + ' ' + formattedValue;
        }

        if (isNegative(value, negativePrefix)) {
          formattedValue = negativePrefix + formattedValue;
        }

        return String(formattedValue);
      },
    },
  };
});
